import { BaseService } from './base.service'

export class AppealsService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  searchAppeal(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/appeals/search', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createAppeal(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/appeals', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  treateAppeal(object) {
    const { appeal, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/appeals/treat/${appeal}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchAppeals(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/appeals', { data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getAppeal(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/appeals/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateAppeal(object) {
    const { id, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/appeals/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  deleteAppeal(id) {
    // console.log('id :>> ', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/appeals/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new AppealsService()
