import appealsService from '@/services/http/appeals.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async searchAppeal({ rootState }, data) {
      const res = await appealsService.searchAppeal(data)
      return res
    },

    async createAppeal({ rootState }, data) {
      const res = await appealsService.createAppeal(data)
      return res
    },

    async treateAppeal({ rootState }, data) {
      const res = await appealsService.treateAppeal(data)
      return res
    },

    async fetchAppeals({ rootState }, data) {
      const res = await appealsService.fetchAppeals(data)
      return res
    },

    async getAppeal({ rootState }, data) {
      const res = await appealsService.getAppeal(data)
      return res
    },

    async updateAppeal({ rootState }, data) {
      const res = await appealsService.updateAppeal(data)
      return res
    },

    async deleteAppeal({ rootState }, data) {
      const res = await appealsService.deleteAppeal(data)
      return res
    },
  },
}
